<template>
    <modal ref="realizarPedido" titulo="Realizar Pedido" icon="checked" @guardar="realizar_pedido">
        <div class="row m-3 justify-center">
            <div class="col-12 text-center">
                Si desea realizar el pedido deja las instrucciones para la entrega.
            </div>
        </div>
        <ValidationObserver ref="form1">
            <div class="row mx-0 justify-center">
                <div class="col-12 text-center">
                    <el-input v-model="model.instrucciones" type="textarea" rows="4" maxlength="1000" placeholder="Por favor digita las instrucciones de entrega." show-word-limit />
                </div>
            </div>
            <div class="row f-14 my-2 mx-0 justify-between">
                <div class="col-7">
                    <p class="ml-2">Fecha de Entrega</p>
                    <ValidationProvider v-slot="{errors}" rules="required" name="Fecha">
                        <el-date-picker
                        v-model="model.entrega_fecha"
                        type="date"
                        size="small"
                        placeholder="Fecha"
                        value-format="yyyy-MM-dd"
                        value="yyyy-MM-dd"
                        :clearable="false"
                        :picker-options="pickerOptions"
                        @change="set_horario"
                        />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <div class="col-5">
                    <p class="ml-2">Hora de Entrega</p>
                    <ValidationProvider v-slot="{errors}" rules="required" name="Horario">
                        <el-select v-model="model.entrega_horario" size="small" placeholder="Horario">
                            <el-option
                            v-for="item in horarios"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            :disabled="item.disabled"
                            />
                        </el-select>
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Almacen from "~/services/almacen/almacen"
import Pedidos from '~/services/pedidos/pedidos-admin'
import moment from 'moment'
export default {
    data(){
        return {
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < moment().subtract(1,'days');
                }
            },
            horarios:[],
            model:{
                entrega_fecha:'',
                instrucciones: '',
                entrega_horario:''
            }
        }
    },
    computed:{
        rol(){
            return this.$usuario.rol_nombre
        },
    },
    methods: {
        toggle(productos){
            this.model = {
                entrega_fecha:'',
                instrucciones: '',
                entrega_horario:'',
                productos:productos
            }
            this.set_franja()
            this.$refs.realizarPedido.toggle()
        },
        async realizar_pedido(){
            try {
                const valid = await this.$refs.form1.validate()
                if(valid){
                    const {data} = await Almacen.copiar_pedido(this.model)
                    this.$refs.realizarPedido.toggle()
                    this.notificacion('Éxito','Pedido realizado correctamente','success')

                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async set_franja(){
            try {
                let id_cedis = this.$tienda.id_cedis
                if(id_cedis === null){
                    this.notificacion('Advertencia','La tienda no tiene un CEDIS asignado.','warning')
                    return
                }
                const {data} = await Pedidos.get_horarios(id_cedis)
                data.horarios.map(h=>{
                    h.disabled = false
                })
                this.horarios = data.horarios

            } catch (e){
                this.error_catch(e)
            }
        },
        set_horario(fecha){
            let hoy = moment().format('YYYY-MM-DD')
            let hora = moment().hour()

            if(hoy === fecha){
                this.horarios.map(h=>{
                    if(h.fin < hora){
                        h.disabled=true
                    }
                })

            }else{
                this.horarios.map(h=>{
                    h.disabled=false
                })
            }

            this.model.entrega_horario = ''
        }
    }
}
</script>

<style lang="css" scoped>
</style>
